import axios from 'axios'
import { mapState, mapMutations } from "vuex"
import router from './router';
import Vue from 'vue';

import pkg from '../package';
const version = pkg.version;


//export default {
export const gw = ({
    computed: {
        ...mapState(['auth','precioscat','loading','bottomNav',"firma","lasttoasted","message"]),
    },
    methods: {
        ...mapMutations(['storeLoading','setAuth', 'setFirma','setBreadcrumbs','setprecioscat','setbottomNav','setPagState','setMessage','sumRouterViewKey']),

        //////////////////////////////////////////////////////////////////////////////////////Api
        api: function (_url, _post, _loading=true) {
            let _this = this;

            ////////////////////////////////////Loading Semaforo
            if(_loading){
                _this.storeLoading({ titulo: 'Cargando', estado: true });
            }
            
            if(_url[0]=="/"){_url = _url.substring(1);}

            var access_token = '';
            var coduser = '';
            var cliente = this.getLocalStorage('auth');
            if(this.auth!=null){
                access_token = this.auth.access_token;
                coduser = this.auth.coduser;
                if (cliente!=undefined) coduser = cliente.coduser;
            }

            var LK = this.getCookie('LK');
            var LP = this.getCookie('LP');
            var LS = this.getCookie('LS');

            return new Promise(function (resolve, reject) {
                //axios.post(process.env.VUE_APP_API_URL + _url, {
                console.log(process.env.VUE_APP_API_URL + _url);
                axios.post(process.env.VUE_APP_API_URL + _url,
                    {_post},
                    {
                        headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'api-token': process.env.VUE_APP_API_TOKEN,
                        'access-token': access_token,
                        'LK': LK,
                        'LP': LP,
                        'LS': LS,
                        'coduser': coduser,
                        'version': version
                        }  
                })
                .then(response => {
                    if(_loading){_this.storeLoading({ titulo: '', estado: false })}
                    if(response.data.code=="412-3" || response.data.code=="UpdateApk"){
                        _this.setPagState("updateapk");
                            console.log("Hay que actualizar la aplicación");
                    }
                    else if(response.data.success){
                        resolve(response.data);
                        _this.setPagState("200");
                    }
                    else{
                        _this.setPagState("errorapi");
                    }
                })
                .catch(e => {
                    console.log("------------- Error gw.getapi -------------");
                    console.log(e);
                    if(_loading){_this.storeLoading({ titulo: '', estado: false })}
                    _this.setPagState("errorapi");
                    reject(e);
                });;
            });
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////LocalStorage
        getLocalStorage: function (_var) {
            if(typeof(localStorage.getItem(_var))!='undefined'){

                var _return = localStorage.getItem(_var)
                if(_return && (_return === 'object' || _return[0]=="{" || _return === 'array' || _return[0]=="[")){
                    _return = JSON.parse(_return);
                }

                return _return
            }
            return null
        },

        setLocalStorage: function (_var, _data) {
            if(typeof _data === 'object' || _data[0]=="{"){
                localStorage.setItem(_var, JSON.stringify(_data));
            }
            else{
                localStorage.setItem(_var, _data);
            }
        },

        delLocalStorage: function (_var) {
            localStorage.removeItem(_var);
        },
        
        deleteCookie: function (_var){
            document.cookie = _var +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////to
        to: function (_ruta,reload="") {
            if(_ruta=="back"){this.$router.back();}
            else{
                this.$router.push(_ruta);
            }

            if(reload=="rerender"){
                //this.$forceUpdate();
                this.sumRouterViewKey();
            }
            else if(reload=="reload"){
                this.$router.go(1);
            }
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////toasted
        toasted: function (_text, _type=null, _icon=null, _actions=null){
            this.lasttoasted.text = _text;
            if(this.lasttoasted.text==_text && this.lasttoasted.type==_type && this.lasttoasted.icon==_icon && this.lasttoasted.action==_actions){

                var _date = new Date();
                var dif = Math.abs((_date.getTime() - this.lasttoasted.date.getTime()));

                if(dif<5000){
                    this.lasttoasted.num++;
                    _text = _text+" ("+this.lasttoasted.num+")";
                }
                else{//reset
                    this.lasttoasted.text==null;
                    this.lasttoasted.num=1;
                }
            }
            else{this.lasttoasted.num=1;}

            this.lasttoasted.date = new Date();
            let obj = {};
            if(_type!=null){this.lasttoasted.type = obj.type = _type;}
            if(_icon!=null){obj.icon = {}; this.lasttoasted.icon = obj.icon.name = _icon;}
            if(_actions!=null){this.lasttoasted.action =  obj.action = _actions}

            Vue.toasted.show(_text, obj);
        },

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////apiPreciosCat
        // apiPreciosCat: function (codcliente){
        //     let precios = localStorage['precioscat'];
        //     let time_allow_precios = parseInt(localStorage['time_allow_precios']);
        //     let datenow = Date.now();

        //     if(codcliente=="" || codcliente==null || codcliente==undefined){return false;}

        //     if(precios == "" || precios == undefined || precios == null || datenow > time_allow_precios){
        //         let post = {'option': 'cat', 'codcliente' : codcliente};
        //         this.api("/peticion/precios", post, false).then(_return => {
        //             if(_return['success']=="1"){
        //                 this.setprecioscat(_return['datos']);
        //                 if (this.precioscat != null && this.precioscat != undefined && this.precioscat != ""){
        //                     datenow += (60 * 60 * 1000); //60 minutos
        //                     localStorage['precioscat'] = JSON.stringify(this.precioscat);
        //                     localStorage['time_allow_precios'] = datenow;
        //                 }
        //                 return true;
        //             }
        //             else{
        //                 //error..
        //                 return false;
        //             }
        //         });
        //     }
        //     else if (this.precioscat.length > 0){
        //         //No hacer nada
        //         return false;
        //     }
        //     else{
        //         //Si llegamos aquí es porque la página se ha recargado y hay que volver a cargar los precios del localStorage al Store.
        //         this.setprecioscat(JSON.parse(localStorage['precioscat']));
        //         return true;
        //     }
        // },

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////tgetCookie
        getCookie: function(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
        },

        formatDate: function(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
          },
        closekeyboard: function(){
            window.addEventListener('native.keyboardshow', function (e) {
                console.log("prueba keyboard");
                console.log(e);
                
                if (document.activeElement === document.body) {
                    window.cordova.plugins.Keyboard.close()
                }
            })
        },
        validarEmail:function(email) {
            var expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!expr.test(email)){ return false; /*error*/}
            else{return true; /*Correcto*/}
        }
    }
});