import Vue from 'vue';
import Vuetify from 'vuetify';
//import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'; // Agrega esta línea si aún no lo has hecho

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#2988c9'
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
