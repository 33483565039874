import Vue from 'vue'
import Router from 'vue-router'


//import {gw} from '@/gw.js'

Vue.use(Router)

//export default new Router({
let router = new Router({
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'hash',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('./views/Login.vue')
    },
    // {
    //   path: '/menu/:tipomenu',
    //   name: 'MenuIcons',
    //   component: () => import('./views/MenuIcons.vue'),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/partesdeservicio',
      name: 'PartesDeServicio',
      component: () => import('./views/PartesDeServicio.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/partedeservicio/:codorden/:vista?',
      name: 'ParteDeServicio',
      component: () => import('./views/ParteDeServicio.vue'),
      meta: {
        requiresAuth: true
      }
    },
    /* {
      path: '/prealbaran/:estado/:codigo',
      name: 'PreAlbaran',
      component: () => import('./views/PreAlbaran.vue'),
      meta: {
        requiresAuth: true
      }
    }, */
    /* {
      path: '/nuevoprealbaran/:codigo', //Sirve para crear un prealbaran nuevo
      name: 'NuevoPreAlbaran',
      component: () => import('./views/PreAlbaran.vue'),
      meta: {
        requiresAuth: true
      }
    }, */
    /* {
      path: '/prealbaran/:codigo/:estado', //Sirve para crear un prealbaran nuevo
      name: 'CrearPreAlbaran',
      component: () => import('./views/PreAlbaran.vue'),
      meta: {
        requiresAuth: true
      }
    }, */
    /* {
      path: '/albaran/:codigo',
      name: 'Albaran',
      component: () => import('./views/Albaran.vue'),
      meta: {
        requiresAuth: true
      }
    }, */
    {
      path: '/parte/:codigo',
      name: 'Parte',
      component: () => import('./views/Parte.vue'),
      meta: {
        requiresAuth: true
      }
    },
    /* {
      path: '/firma/',
      name: 'Firma',
      component: () => import('./views/Firma.vue'),
      meta: {
        requiresAuth: true
      }
    }, */
    // {
    //   path: '/articulos',
    //   name: 'Colecciones',
    //   component: () => import('./views/Articulos.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   }
    // },
    /*{
      path: '/articulos/:codcampanya',
      name: 'FamiliasColeccion',
      component: () => import('./views/Articulos.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/articulos/:codcampanya/:codfamilia',
      name: 'ArticulosColeccionFamilia',
      component: () => import('./views/Articulos.vue'),
      meta: {
        requiresAuth: true
      }
    },*/
    // {
    //   path: '/cuenta',
    //   name: 'Cuenta',
    //   component: () => import('./views/Cuenta.vue'),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/clientes',
    //   name: 'Clientes',
    //   component: () => import('./views/Clientes.vue'),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/articulo/:referencia',
    //   name: 'Articulo',
    //   component: () => import('./views/Articulos.vue'),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    /*
    {
      path: '/carro',
      name: 'carro',
      component: () => import('./views/Carro.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/estadisticaspedido',
      name: 'EstadisticasPedido',
      component: () => import('./views/EstadisticasPedido.vue'),
      meta: {
        requiresAuth: true,
        requiresClient: true
      }
    },
    {
      path: '/Subfamilias',
      name: 'SubFamilia',
      component: () => import('./views/SubFamilia.vue'),
      meta: {
        requiresAuth: true
      }
    },
    */
    { path: '*', redirect: '/login' }

  ]
})

router.beforeEach((_to, _from, _next) => {   
  let auth = JSON.parse(localStorage.getItem('auth'));
  
  if(auth && !auth.access_token){  //Esto no debe pasar nunca.. Comprobamos por si hay algún ataque (Lo dudo) o por error en el Local Storage. Eliminamos todo y Reload 
    localStorage.removeItem('auth');
    location.reload();
  }

  if(!auth && _to.name!="Login"){//No está logueado, redirigir a Login
    router.push('Login')
  }

  if(auth && (_to.name=="Login" || _to.name==null)){//No debe estar nunca en login si está logueadobundleRenderer.renderToStream
    switch (auth.rol) {
      case 'operario':
          router.push('/partesdeservicio')
          break;
    }
  }

  

  _next();
})


export default router;